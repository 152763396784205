import React from "react"
import LayoutThird from "../../components/common/layout-third";
import SEO from "../../components/common/seo";
import SignatureNav from "../../components/common/signature-nav";
import Partners from "../../components/common/partners";
import Interested from "../../components/signature/interested";
import ModalContactSales from "../../components/modals/modal-contact-sales";
import ModalWhitePaper from "../../components/modals/modal-white-paper";
import ContactBox from "../../components/common/contact-box";
import {OutboundLink} from "gatsby-plugin-google-gtag";
import {graphql} from "gatsby";
import ReactMarkdown from "react-markdown";
import {customElements} from "../../utils/global-functions";
import rehypeRaw from "rehype-raw";

const IndexPage = ({data, pageContext}) => {
    const lang = pageContext.locale.path.default;
    const page = data.allStrapiSignatureHome.edges[0].node;
    const customKycElements = (pClassName, bClassName, ulClassName, liClassName) => {
        return {
            p: ({node, ...props}) => <p className={pClassName} {...props} />,
            ul: ({node, ...props}) => <div className="check-list">
                <ul {...props} className={ulClassName}/>
            </div>,
            li: ({node, ...props}) => <li className={liClassName} {...props} style={{position: 'relative', left: '-40px'}}/>,
            strong: ({node, ...props}) => <strong className={bClassName} {...props}/>
        }
    }

    return (
        <LayoutThird pageContext={pageContext} cookies={data.cookies} favicon="favicon-sig.png">
            <SEO lang={lang} title={page.seo.title} image={page.seo.image.localFile.publicURL} description={page.seo.meta_description}/>

            <SignatureNav pageContext={pageContext}/>

            <div id="product-top" className="content">
                <header className="masthead">
                    <div className="container h-100">
                        <div className="row h-100 align-items-center">
                            <div className="col-xl-5 text-left" data-aos="fade-right">
                                <h1 className="spacing mb-4 display-4">
                                    <img src={require("../../img/signature-white.png").default} width="42" className="mobile mr-3 badge-signature" alt="Signature"/>
                                    {page.title}
                                </h1>

                                <ReactMarkdown children={page.description} rehypePlugins={[rehypeRaw]} components={customElements('spacing mb-4')}/>

                                <p>
                                    {lang === 'es' && <OutboundLink href="https://www.tecalis.com/es/signature/go" className="btn btn-primary spacing btn-sm mr-4 spacing">
                                        Probar Signature GRATIS
                                    </OutboundLink>}

                                    {lang !== 'es' && <a href="#" className="btn btn-primary spacing btn-sm mr-4 spacing" data-toggle="modal" data-target="#modal-contact-sales">
                                        Try Signature for FREE
                                    </a>}
                                </p>
                            </div>
                            <div className="col-xl-7 text-center" data-aos="fade-left">
                                <div className="img-skewed img-skewed-left">
                                    <img src={require("../../img/products/signature.png").default} className="rounded img-fluid mw-md-130 img-skewed-item header-presentation"
                                         alt="..."
                                         style={{position: 'relative', top: '-23px'}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            </div>

            <section id="more-info" className="py-11 functions-sig border-top bg-grey-1" style={{position: 'relative', zIndex: 90}}>
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                            <a href="#more-info" className="btn btn-red btn-rounded-circle shadow smooth-scroll" style={{marginTop: '-220px'}}>
                                <i className="fe fe-arrow-down"/>
                            </a>
                        </div>
                    </div>

                    <div className="row mt-2">
                        <div className="col-xl-12" data-aos="fade-down" data-aos-delay="0">
                            <div className="jumbotron bg-grey" style={{padding: '40px'}}>
                                <h3 className="spacing font-weight-bold"><b>{page.ue_provider_title}</b></h3>
                                <ReactMarkdown children={page.ur_provider_description} rehypePlugins={[rehypeRaw]} components={customElements('spacing font-size-md', 'link-tecalis font-weight-bold')}/>

                                <br/>

                                <div align="center" className="row">
                                    <div className="col-md-10 offset-md-1">
                                        <div className="row">
                                            <div className="col-6 col-xl-2 mb-2">
                                                <img src={require("../../img/eidas.png").default} width="130" style={{position: 'relative', top: '15px'}} className="logo-filter"
                                                     alt="eidas"/>
                                            </div>
                                            <div className="col-6 col-xl-2 mb-2">
                                                <img src={require("../../img/pyme.png").default} width="100" className="logo-filter" alt="pyme"/>
                                            </div>
                                            <div className="col-6 col-xl-2 mb-2">
                                                <img src={require("../../img/cer-9001.png").default} width="70" className="logo-filter" alt="cer"/>
                                            </div>
                                            <div className="col-6 col-xl-2 mb-2">
                                                <img src={require("../../img/9001-nuevo.svg").default} width="70" style={{position: 'relative', top: '5px'}} className="logo-filter"
                                                     alt="9001"/>
                                            </div>
                                            <div className="col-6 col-xl-2 mb-2">
                                                <img src={require("../../img/27001-nuevo.svg").default} width="70" style={{position: 'relative', top: '5px'}}
                                                     className="logo-filter"
                                                     alt="27001"/>
                                            </div>
                                            <div className="col-6 col-xl-2 mb-2">
                                                <img src={require("../../img/eureka.png").default} width="130" style={{position: 'relative', top: '6px'}} className="logo-filter"
                                                     alt="eureka"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br/><br/>
                        </div>
                    </div>

                    <div className="row mb-10">
                        <div className="col-xl-12" data-aos="fade-down" data-aos-delay="0">
                            <hr className="black-line"/>
                            <h3>
                                <ReactMarkdown children={page.solution_text} rehypePlugins={[rehypeRaw]}/>
                            </h3>

                            <br/><br/>
                        </div>
                    </div>

                    <div className="row mb-10">
                        <div className="col-xl-3 check-list">
                            {page.solutions.slice(0, 3).map((solution, i) => {
                                return <div data-aos="fade-down" data-aos-delay={(i + 1) * 100} key={i}>
                                    <h3 className="mt-8 spacing font-weight-bold"><i className={`fe ${solution.fe_icon} icon-land text-tecalis`}/> {solution.title}</h3>
                                    <ReactMarkdown children={solution.description} rehypePlugins={[rehypeRaw]} components={customElements('spacing font-size-md')}/>
                                </div>
                            })}
                        </div>

                        <div className="col-xl-1"/>

                        <div className="col-xl-8">
                            <div className="position-relative pt-7 mb-md-9 mt-5 rounded-lg overflow-hidden" data-aos="fade-left" data-aos-delay="300">
                                <div className="position-absolute top-0 right-0 bottom-0 left-0 bg-gradient-multicolor2">
                                    <div className="d-flex" style={{paddingTop: '15px', paddingLeft: '15px'}}>
                                        <span className="bg-danger rounded-circle" style={{width: '10px', height: '10px'}}/>
                                        <span className="bg-warning rounded-circle ml-2" style={{width: '10px', height: '10px'}}/>
                                        <span className="bg-success rounded-circle ml-2" style={{width: '10px', height: '10px'}}/>
                                    </div>
                                </div>

                                <div className="video-container">
                                    <div className="embed-responsive embed-responsive-16by9">
                                        <div className="progress-bar">
                                            <div className="progress">
                                                <div className="progress-inner">
                                                    <span className="progress-time"/>
                                                    <span className="progress-value"/>
                                                </div>
                                            </div>
                                            <div className="buffer"/>
                                        </div>

                                        <div className="progress-overlay"/>

                                        <video className="embed-responsive-item bg-light shadow-lg" src={require('../../video/cap1.mp4').default} loop muted>
                                            Sorry, your browser doesn't support embedded videos,
                                            but don't worry, you can <a href={require('../../video/cap1.mp4').default} download>download it</a>
                                            and watch it with your favorite video player!
                                        </video>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="row mb-10">
                        <div className="col-xl-8">
                            <div className="position-relative pt-7 mb-md-1 mt-5 rounded-lg overflow-hidden" data-aos="fade-right" data-aos-delay="500">
                                <div className="position-absolute top-0 right-0 bottom-0 left-0 bg-gradient-multicolor2">
                                    <div className="d-flex" style={{paddingTop: '15px', paddingLeft: '15px'}}>
                                        <span className="bg-danger rounded-circle" style={{width: '10px', height: '10px'}}/>
                                        <span className="bg-warning rounded-circle ml-2" style={{width: '10px', height: '10px'}}/>
                                        <span className="bg-success rounded-circle ml-2" style={{width: '10px', height: '10px'}}/>
                                    </div>
                                </div>

                                <div className="video-container">
                                    <div className="embed-responsive embed-responsive-16by9">
                                        <div className="progress-bar">
                                            <div className="progress">
                                                <div className="progress-inner">
                                                    <span className="progress-time"/>
                                                    <span className="progress-value"/>
                                                </div>
                                            </div>

                                            <div className="buffer"/>
                                        </div>

                                        <div className="progress-overlay"/>

                                        <video className="embed-responsive-item bg-light shadow-lg" src={require('../../video/cap2.mp4').default} loop muted>
                                            Sorry, your browser doesn't support embedded videos,
                                            but don't worry, you can <a href={require('../../video/cap2.mp4').default} download>download it</a>
                                            and watch it with your favorite video player!
                                        </video>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-1"/>

                        <div className="col-xl-3 check-list" data-aos="fade-up" data-aos-delay="0">
                            <br/>

                            {page.solutions.slice(3, 6).map((solution, i) => {
                                return <div data-aos="fade-down" data-aos-delay={(i + 1) * 100} key={i}>
                                    <h3 className="mt-8 spacing font-weight-bold"><i className={`fe ${solution.fe_icon} icon-land text-tecalis`}/> {solution.title}</h3>
                                    <ReactMarkdown children={solution.description} rehypePlugins={[rehypeRaw]} components={customElements('spacing font-size-md')}/>
                                </div>
                            })}
                        </div>
                    </div>

                    <div className="row mt-12">
                        <div className="col-xl-5 check-list" data-aos="fade-up" data-aos-delay="0">
                            <br/><br/><br/>

                            {page.solutions.slice(6, 9).map((solution, i) => {
                                return <div data-aos="fade-down" data-aos-delay={(i + 1) * 100} key={i}>
                                    <h3 className="mt-8 spacing font-weight-bold"><i className={`fe ${solution.fe_icon} icon-land text-tecalis`}/> {solution.title}</h3>
                                    <ReactMarkdown children={solution.description} rehypePlugins={[rehypeRaw]} components={customElements('spacing font-size-md')}/>
                                </div>
                            })}
                        </div>

                        <div className="col-xl-1"/>

                        <div className="col-xl-5 mt-1" data-aos="fade-down">
                            <div className="position-absolute top-center top-md-left text-warning mt-n2 ml-md-n8" style={{position: 'relative', top: '43px'}}>
                                <img src={require("../../img/products/ic_decoration.svg").default} alt="Decoration"/>
                            </div>

                            <div className="d-flex flex-column w-md-130 mb-6 mb-md-0 position-relative">
                                <img className="img-fluid rounded shadow align-self-start" data-jarallax-element="-20" src={require("../../img/uikit-3.png").default} alt="..."
                                     style={{minHeight: '1px', maxWidth: '50%', marginLeft: '40%'}}/>

                                <img className="img-fluid rounded shadow align-self-start" data-jarallax-element="-50" src={require("../../img/uikit-2.png").default} alt="..."
                                     style={{minHeight: '1px', maxWidth: '50%', marginTop: '-35%'}}/>

                                <img className=" img-fluid rounded shadow align-self-center" data-jarallax-element="-80" src={require("../../img/uikit-1.png").default} alt="..."
                                     style={{minHeight: '1px', maxWidth: '50%', marginTop: '-20%'}}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-5 pt-10 pb-8 kyc bg-grey">
                <div className="container">
                    <div className="row">
                        <div align="center" className="col-xl-6">
                            <div className="device-combo device-combo-iphonex-iphonex mb-6 mb-md-0">
                                <div className="device device-iphonex" data-aos="fade-down">
                                    <img src={require("../../img/products/identity-mock-m5.png").default} className="device-screen" alt="..."/>
                                    <img src={require("../../img/products/iphonex.svg").default} className="img-fluid ip" alt="..."/>
                                </div>

                                <div className="device device-iphonex" data-aos="fade-up">
                                    <img src={require("../../img/products/signature-m2.png").default} className="device-screen ip-3" alt="..."/>
                                    <img src={require("../../img/products/iphonex.svg").default} className="img-fluid" alt="..."/>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-6" data-aos="fade-left">
                            <h2 className="pt-10 display-4 font-weight-bold spacing">
                                {page.kyc_title}
                            </h2>

                            <ReactMarkdown children={page.kyc_description}
                                           rehypePlugins={[rehypeRaw]}
                                           components={customKycElements('font-size-lg text-black-80 spacing mt-6 mb-4', 'font-weight-bold text-tecalis', 'spacing', 'font-size-lg')}/>

                            <img src={require("../../img/products/arrow.svg").default} className="kyc-arrow" alt="..."/>
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-5 benefits">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-12 text-center">
                            <div align="center">
                                <br/>
                                <h4>
                                    <span className="badge rounded-pill bg-tecalis mt-3 py-2 pr-3 pl-3 spacing text-white">
                                        {page.benefits_subtitle}
                                    </span>
                                </h4>
                                <br/>
                                <h2 className="display-4 font-weight-bold spacing">{page.benefits_title}</h2>
                                <br/>
                            </div>

                            <div className="row mt-2 mb-5">
                                {page.benefits_items.map((item, i) => {
                                    console.log(item);
                                    return <div className="col-xl-12" key={i}>
                                        <div className="card hvr-float mb-4">
                                            <div className="card-body pt-2">
                                                <div className="row">
                                                    <div className="col-xl-2">
                                                        <img alt={item.title} src={item.icon.localFile.publicURL} className="img-fluid mt-9" width={80}/>
                                                    </div>
                                                    <div className="col-xl-9">
                                                        <h4 align="left" className="spacing">{item.title}</h4>

                                                        <ReactMarkdown children={item.description}
                                                                       rehypePlugins={[rehypeRaw]}
                                                                       components={customKycElements('font-size-lg text-black-80 spacing mb-4 text-left', 'font-weight-bold text-tecalis')}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                })}

                                <div className="col-xl-12 mt-8 text-center" data-aos="fade-up">
                                    <div className="device-combo device-combo-macbook-iphonex">
                                        <div className="device device-macbook">
                                            <img src={require("../../img/signature2.png").default} className="device-screen ip-2" alt="..."/>
                                            <img src={require("../../img/macbook.svg").default} className="img-fluid" alt="..."/>
                                        </div>

                                        <div className="device device-iphonex">
                                            <img src={require("../../img/signature-m.png").default} className="device-screen ip-3" alt="..."/>
                                            <img src={require("../../img/iphonex.svg").default} className="img-fluid" alt="..."/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="pt-10 bg-grey">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-6 text-center" data-aos="fade-down">
                            <br/><br/>

                            <h4 style={{fontSize: '23px'}}><span className="badge rounded-pill bg-tecalis mt-3 py-2 pr-3 pl-3 spacing text-white">Integraciones</span></h4>
                            <br/>

                            <h2 className="display-4 font-weight-bold spacing">
                                {page.integration_title}
                                <br/><br/>
                            </h2>

                            <ReactMarkdown children={page.integration_description} rehypePlugins={[rehypeRaw]} components={customElements('font-size-lg mb-6 spacing text-left')}/>
                        </div>

                        <div className="col-12 col-md-10 col-lg-6 text-center" data-aos="fade-up">
                            <img src={require('../../img/integraciones.png').default} width="380px" className="img-fluid mt-2 mb-5" alt="..."
                                 style={{position: 'relative', bottom: '-30px', zIndex: '-1'}}/>
                        </div>
                    </div>
                </div>
            </section>

            <Interested pageContext={pageContext}/>

            <Partners pageContext={pageContext}/>

            <ContactBox pageContext={pageContext} product="Signature"/>

            <ModalContactSales pageContext={pageContext} product="Signature"/>

            <ModalWhitePaper pageContext={pageContext}/>

            {/*<ModalSignatureDemo/>*/}
        </LayoutThird>
    )
}

export default IndexPage

export const indexPageQuery = graphql
    `query($lang: String)
    {
        allStrapiSignatureHome ( filter: { locale: { eq: $lang } } )
        {
            edges {
                node {
                    id
                    seo {
                        title
                        meta_description
                        image {
                            localFile {
                                publicURL
                            }
                        }
                    }
                    title
                    description
                    ue_provider_title
                    ur_provider_description
                    solution_text
                    solutions {
                        order
                        fe_icon
                        title
                        description
                    }
                    kyc_title
                    kyc_description
                    benefits_title
                    benefits_subtitle
                    benefits_items {
                        order
                        title
                        icon {
                            localFile {
                                publicURL
                            }
                        }
                        description
                    }
                    integration_title
                    integration_description
                }
            }
        }
        cookies: markdownRemark(
            frontmatter : {
                lang: { eq: $lang }
            name: {
                eq: "cookies"
            }
        }
    ) {
        frontmatter
            {
                cookiesStart
                cookiesEnd
                linkText
            }
        }
    }
`;
